import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as PinDropOutlined } from "../assets/images/location-pin.svg";
import { ReactComponent as WorkOutlineOutlined } from "../assets/images/work.svg";
import GText from "./GText";
import { StringsContext } from "../DataFactory/useStrings";

const TaskDiv = styled.div`
  position: relative;
  svg {
    margin-top: 5px;
    width: 26px;
    height: 26px;
  }
`;
const PickupDiv = styled.div`
  display: inline-flex;
  width: 95%;
  &:after {
    content: " ";
    position: absolute;
    display: block;
    top: 20px;
    left: 12px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    min-height: 15px;
    max-height: 20px;
  }
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    margin-bottom: 10px;
    vertical-align: top;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
`;
const DeliveryDiv = styled.div`
  display: inline-flex;
  width: 95%;
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    vertical-align: top;
    padding-bottom: 10px;
  }
`;

export default function GTaskView(props) {
  const [strings] = useContext(StringsContext);
  return (
    <TaskDiv>
      <PickupDiv>
        <PinDropOutlined />
        <div>
          <GText
            semi
            g5
            text={props.type === "pickup" ? strings.w_home : props.storeTitle}
          />
          <GText
            style={{ marginTop: 2, color: "#555" }}
            text={props.task.pickup_address}
          />
        </div>
      </PickupDiv>
      <DeliveryDiv>
        <WorkOutlineOutlined />
        <div>
          <GText
            semi
            g5
            text={props.type === "pickup" ? props.storeTitle : strings.w_home}
          />
          <GText
            style={{ marginTop: 2, color: "#555" }}
            text={props.task.delivery_address}
          />
        </div>
      </DeliveryDiv>
    </TaskDiv>
  );
}
