import orderIcon from "../assets/images/sidebar/orders.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import reviewIcon from "../assets/images/sidebar/reviews.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import reportsIcon from "../assets/images/sidebar/reports.svg";

export const menus = [
  // {
  //   icon: orderIcon,
  //   title: "Active Orders",
  //   path: "/active-orders",
  //   state: "aOrder",
  //   isLink: true,
  //   isStaff: "order_index",
  // },
  {
    icon: orderIcon,
    title: "Orders",
    path: "/orders",
    state: "order",
    isLink: true,
    isStaff: "order_index",
  },
  {
    icon: productIcon,
    title: "Products",
    path: "/products",
    state: "product",
    isLink: true,
    isStaff: "product_index",
    children: [
      {
        icon: "📋",
        title: "Categories",
        path: "/categories",
        state: "product",
        isLink: true,
      },
    ],
  },
  {
    icon: couponIcon,
    title: "Promotions",
    path: "/coupons",
    state: "coupon",
    isLink: true,
    isAdd: true,
    isStaff: "coupon_index",
  },
  {
    icon: reviewIcon,
    title: "Reviews",
    path: "/reviews",
    state: "review",
    isLink: true,
    isStaff: "review_index",
  },
  {
    icon: reportsIcon,
    title: "Holiday Hours",
    state: "holiday_hour",
    isLink: true,
    path: "/holiday-hours",
    isStaff: false,
  },
  {
    icon: withdrawalIcon,
    title: "Wallet",
    state: "wallet",
    isLink: true,
    path: "/wallet",
    isStaff: "store_wallet",
  },
];
