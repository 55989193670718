import React, { useContext } from "react";
import { ReactComponent as MoreVert } from "../assets/images/icons/morevert.svg";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { Paper, ClickAwayListener, MenuItem, MenuList } from "@mui/material";
import styled from "styled-components";
import { StringsContext } from "../DataFactory/useStrings";

const ToggleButton = styled.button`
  display: inline-block;
  min-width: auto;
  padding: 5px 6px 1px;
  background: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  outline: 0;
  & svg {
    height: 1.3em;
    width: 1.3em;
  }
`;

export default function MenuListComposition(props) {
  const [strings] = useContext(StringsContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div style={Object.assign({ position: "relative" }, props.style)}>
      <div>
        <ToggleButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVert />
        </ToggleButton>
        {open && (
          <Paper
            sx={{ width: 100 }}
            style={Object.assign(
              { position: "absolute", right: "0", zIndex: 9 },
              props.paperStyle
            )}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                className={props.mode || ""}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                style={{ padding: "5px 0" }}
              >
                {props.onEdit ? (
                  <MenuItem
                    onClick={(e) => {
                      props.onEdit();
                      handleClose(e);
                    }}
                    style={props.menuStyle}
                  >
                    {props.icons == true && (
                      <Edit
                        fontSize="small"
                        style={{
                          fill: "#777779",
                          paddingRight: "8px",
                          width: "19px",
                        }}
                      />
                    )}
                    {props.menuEditTitle || strings.w_edit}
                  </MenuItem>
                ) : (
                  ""
                )}
                {props.onDelete ? (
                  <MenuItem
                    onClick={(e) => {
                      props.onDelete(e);
                      handleClose(e);
                    }}
                    style={props.menuStyle}
                  >
                    {props.icons == true && (
                      <Delete
                        style={{
                          fill: "#777779",
                          paddingRight: "8px",
                          width: "19px",
                        }}
                      />
                    )}
                    {strings.w_delete}
                  </MenuItem>
                ) : (
                  ""
                )}

                {props.onSelect && (
                  <MenuItem
                    onClick={(e) => {
                      props.onSelect();
                      handleClose(e);
                    }}
                    style={props.menuStyle}
                  >
                    {props.menuTitle}
                  </MenuItem>
                )}
                {props.onRemove && (
                  <MenuItem
                    onClick={(e) => {
                      props.onRemove(e);
                      handleClose(e);
                    }}
                    style={props.menuStyle}
                  >
                    {strings.w_remove}
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </div>
    </div>
  );
}
